import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { OnboardingService } from '@core/services/onboarding.service';
import { MatDialog } from '@angular/material/dialog';
import { SuccessModalComponent } from '../success-dialog/success-modal.component';
import { FailModalComponent } from '../fail-dialog/fail-modal.component';
import { DataModalComponent } from '../data-dialog/data-modal.component'; 
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { dateValidator } from 'src/app/shared/validators/dateValidator';


@Component({
  selector: 'shd-onboarding-form',
  templateUrl: './onboarding-form.component.html',
  styleUrls: ['./onboarding-form.component.scss'], 
})

export class OnboardingFormComponent implements OnInit {
  onboardingForm: FormGroup;
  phoneParam: any = '';
  today = new Date();
  children = false;
 // disabledSubmit=false;

  /*  7geaze4k */
  constructor(
    private readonly router:Router,
    private readonly activatedRoute:ActivatedRoute,
    private readonly formBuilder: FormBuilder,
    private readonly onboardingService: OnboardingService,
    private readonly dialog: MatDialog,
  ) { 
    this.onboardingForm = this.formBuilder.group({
      identificationNumber:['', Validators.required],
      gender:[1,],
      dateOfBirth:[null,Validators.required],
    }, {
        validators:[ dateValidator]
      } 
    )
   // this.validateIdentificationParam()
  }

  ngOnInit() {
    this.validateIdentificationParam();
  }
  
  singIn() {
    //  this.disabledSubmit=true;
    console.log(this.onboardingForm.invalid);
  
    if ( this.onboardingForm.invalid ) {
      return
    }
    
    const {identificationNumber,...payload} = this.formatRequest();
    console.log(this.formatRequest())
    this.onboardingService.register$(identificationNumber,payload)
    .subscribe({
      next:(response:any) => {
        console.log(response,'exito')
        this.openSuccessDialog()
        //  this.disabledSubmit=false;
      },
      error:(error:any) => {
        console.log(error,'help')
        const errorMessage = error.error.message;
        console.log('errrors',error.error);
        
        if ( errorMessage ) {
          console.log('errrors',error.error.errors)
          if ( errorMessage.includes("Tu historia médica ya fue actualizada") ){
            this.openDataDialog();  
          //  this.disabledSubmit=false;   
          } else { 
            this.openFailDialog();
            //  this.validateIdentificationParam()
            //    this.disabledSubmit=false;            
          } 
        } else { 
          this.openFailDialog();
        //  this.validateIdentificationParam()
        //  this.disabledSubmit=false;            
        }
      }
    })
  }


  validateIdentificationParam() {
    this.activatedRoute.url.subscribe(segments => {     
      const IdentificationNumber = segments[segments.length - 1]?.path;
      console.log('psnap',this.activatedRoute)
      console.log('id',IdentificationNumber)
      

      if ( !IdentificationNumber ) {
        return false
      }

      // const decodeIdentification =  parseInt(IdentificationNumber, 36).toString();
      
      this.onboardingForm.setValue({          
        identificationNumber:IdentificationNumber,
        dateOfBirth : null,
        gender:1
      })
      //  console.log('id',IdentificationNumber)
      return true
    });
  }


  openFailDialog() {
    const dialogRef = this.dialog.open( 
      FailModalComponent, {  
        disableClose: true,  
        panelClass:'onboarding-dialog' 
      }
    );
    
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result)
      this.singIn()
    });
  }

  openSuccessDialog() {
    const dialogRef = this.dialog.open(
      SuccessModalComponent, {  
        disableClose: true,  
        panelClass:'onboarding-dialogIII' 
      }
    );
    
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result)
      console.log(result)
    });
  
  }

  openDataDialog() {
    const dialogRef = this.dialog.open(
      DataModalComponent, {  
        disableClose: true,  
        panelClass:'onboarding-dialogIII' 
      }
    );

    dialogRef.afterClosed().subscribe((result: any) => {
      if ( result ) {
        console.log(result)
      }
    });

  }

  formatRequest() {
    const { identificationNumber,...data } = this.onboardingForm.value;
    console.log('identifr',identificationNumber);
    return {
      identificationNumber,
      data: {
        ...data, 
        children:this.children
      }
    } 
  }
}
