import { Injectable } from '@angular/core';
import { Observable  } from 'rxjs';
import { ShdOnboardingHttpService } from '@core/http/http.service';


@Injectable()
export class OnboardingService {
  constructor(private shdHttpService: ShdOnboardingHttpService) {}
 
  register$(identificationNumber:string,data:any): Observable<any> {
    console.log('dataregister',data);
    return this.shdHttpService.patch$(`/patients/${identificationNumber}/update-patient-onboarding`, data.data);
  }

 

}