import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[numbersOnlyVe]',
})
export class OnlyNumberVeDirective {
  @Input() country: string = '';

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInputChange(event: any) {
    const initialValue = this.el.nativeElement.value;
    const inputChar = event.data;
    const pattern = /[0-9]/;

    if (
     /* this.country == '+58' &&*/
      !pattern.test(inputChar) &&
      event.inputType != 'deleteContentBackward'
    ) {
      this.el.nativeElement.value = initialValue.slice(
        0,
        initialValue.length - 1
      );
    }
    if (initialValue !== this.el.nativeElement.value) {
      event.stopPropagation();
    }
  }
}

@Directive({
  selector: '[numbersOnly]',
})
export class OnlyNumberDirective {
  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInputChange(event: any) {
    const initialValue = this.el.nativeElement.value;

    this.el.nativeElement.value = initialValue.replace(/[^0-9]*/g, '');
    if (initialValue !== this.el.nativeElement.value) {
      event.stopPropagation();
    }
  }
}
