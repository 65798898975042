import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { OnboardingComponent } from './pages/onboarding/onboarding.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: '',
  
    component: OnboardingComponent,
  },
  {
    path: ':id',
  
    component: OnboardingComponent,
  },
  
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes/*,  { useHash: true } */)],
  exports: [RouterModule],
})
export class AppRouterModule {}
