import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {FormBuilder, FormGroup} from '@angular/forms';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'shd-data-modal',
  templateUrl: './data-modal.component.html',
  styleUrls: ['./data-modal.component.scss']
})
export class DataModalComponent implements OnInit {
  redirectTo = environment.pwaUrl;

  constructor(
    public dialogRef: MatDialogRef<DataModalComponent>,
    public fb: FormBuilder
  ) {
   }

  ngOnInit() {
    
  }


  onNoClick() {
    this.dialogRef.close('cerrar');
  }
}
