<div  style="padding: 50px;">
  <div mat-dialog-title style="display: flex;justify-content: end;" >
    <img  src="assets/logo.svg" alt="slices.vip" class="logoweb">
    <img  src="assets/logomovil.svg" alt="slices.vip" class="logomovil">
  </div>
  <div style="display: flex;justify-content: center;" class="margen-icono" >
    <div style="width: 100px; height: 100px; border-radius: 50%;background-color:#2751A51A;justify-content: center;
    display: flex;align-items: center;">
      <div style="width: 81.46px;height: 81.46px;;background-color: #070046;border-radius: 50%;display: flex;justify-content: center;align-items: center;">

        <img src="assets/alert.svg" style="width: 50%;" alt="">
      </div>
    </div>
  </div>
    <h1 style="text-align: center;color:#020646 ;font-size: 40px;margin-top: 30px;font-weight: 700;line-height: 57.29px;" class="information">Tu historia médica ya fue actualizada</h1>
 
  
    <div mat-dialog-content >
      
      <div class="sub-title" >
        Habla con un médico ya! Ingresando a la aplicación de HolaDOC 
      </div>
      
    </div>
  <div mat-dialog-content style="display: flex;justify-content: center;gap: 63px;margin-left: 40px;"  class="grid">
   <!--  <a   href="https://bit.ly/3ys8Wpg"  class="imagenes" style="display: flex;text-decoration:none;"> -->
    <a   [href]="redirectTo"  class="imagenes" style="display: flex;text-decoration:none;"> 
      <img  src="assets/corazon.png" alt="logoazul" class="imagenheart">
   
  <div class="imagenapp imageninitial altitudeo" style="width:161px;">
   <p> <b style="color: #CE252D;font-weight: 700;">Ir la app </b>
    de  HolaDOC ¡Click aquí!</p>
  </div>
    </a>
   
    <a  href="tel:+582127204992" class="imagenes disabled" style="display: flex;text-decoration:none;">    
 
      <img  src="assets/callphone.png" alt="call" class="imagenheart">
    <div class="imagenapp imageninitial altitudet" >
      <p>Contáctanos por <b  style="color: #CE252D;"> nuestra central médica telefónica</b>
       Llama: (0212) 720 49 92 </p>
     </div>
    </a>  
    </div>
    
</div>