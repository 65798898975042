<div  style="padding: 50px;">
  <div mat-dialog-title style="display: flex;justify-content: end;" >
    <img  src="assets/logo.svg" alt="slices.vip" class="logoweb">
    <img  src="assets/logomovil.svg" alt="slices.vip" class="logomovil">
  </div>
  <div style="display: flex;justify-content: center;"  class="margen-icono">
    <div style="width: 100px; height: 100px; border-radius: 50%;background-color:#2751A51A;justify-content: center;
    display: flex;align-items: center;">
      <div style="width: 81.46px;height: 81.46px;background-color: #070046;border-radius: 50%;display: flex;justify-content: center;align-items: center;">

        <img src="assets/alert.svg" style="width: 50%;" alt="">
      </div>
    </div>
  </div>
    <h1 style="text-align: center;color:#020646 ;font-size: 30px;margin-top: 30px;font-weight: 700;
    line-height: 40.92px;">¡Lo sentimos!</h1>
  
 
  <div mat-dialog-content >
    <div class="title">
      Tuvimos inconvenientes para guardar tus datos. 
    </div>
    <div class="sub-title" >
      Intenta nuevamente o contáctanos a soporte@holadoc.com
    </div>
    
  </div>
  <div mat-dialog-actions class="button-container">
    <a  class="button-purple"  (click)="againClick()">Reintentar  </a>
    <a   (click)="onNoClick()">Regresar  </a>
  </div>
</div>