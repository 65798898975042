<div id="contenedor">
     <div class="logo">
         <img src="assets/logo.svg" alt="" class="imageninitial"> 
         <img src="assets/logomovil.svg" alt="" class="imagenend"> 
     </div>
      <div class="container-center">
    <div class="register"><shd-onboarding-form></shd-onboarding-form></div>
    <div class="doctor">
        <img src="assets/doctor.svg" alt="">
    </div>
    </div> 

</div>