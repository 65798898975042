import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {FormBuilder, FormGroup} from '@angular/forms';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'shd-success-modal',
  templateUrl: './success-modal.component.html',
  styleUrls: ['./success-modal.component.scss']
})
export class SuccessModalComponent implements OnInit {
  redirectTo = environment.pwaUrl;
  constructor(
    public dialogRef: MatDialogRef<SuccessModalComponent>,
    public fb: FormBuilder
  ) {
   }

  ngOnInit() {
    
  }


  onNoClick() {
    this.dialogRef.close('cerrar');
  }
}
