import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OnboardingFormComponent } from './onboarding-form/onboarding-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule,MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { LayoutModule } from '@angular/cdk/layout';
import { FlexLayoutModule } from '@angular/flex-layout';
import {MatIconModule} from '@angular/material/icon';
import { CoreModule } from '@core/core.module';
import { OnboardingComponent } from './onboarding.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { OnlyNumberVeDirective, OnlyNumberDirective } from 'src/app/shared/directives/onboarding-form.directive';
import {MatSelectModule} from '@angular/material/select';
import {MatDialogModule} from '@angular/material/dialog';
import { SuccessModalComponent } from './success-dialog/success-modal.component';
import { FailModalComponent } from './fail-dialog/fail-modal.component';
import { DataModalComponent } from './data-dialog/data-modal.component'; 
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import {MatSlideToggleModule} from '@angular/material/slide-toggle'; 
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';
import * as _moment from 'moment';

@NgModule({
  declarations: [
    OnboardingComponent,
    OnboardingFormComponent,
    OnlyNumberVeDirective,
    OnlyNumberDirective,
    SuccessModalComponent,
    FailModalComponent,
    DataModalComponent 
],
  imports: [
    CoreModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    LayoutModule,
    FlexLayoutModule,
    MatIconModule,
    MatExpansionModule,
    MatSelectModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    BrowserModule,
    BrowserAnimationsModule,
    
  ],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
  { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
 /*  {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {floatLabel: 'always'}}*/],
  exports:[OnboardingComponent]
})
export class OnboardingModule {}
