import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {FormBuilder, FormGroup} from '@angular/forms';
@Component({
  selector: 'shd-fail-modal',
  templateUrl: './fail-modal.component.html',
  styleUrls: ['./fail-modal.component.scss']
})
export class FailModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<FailModalComponent>,
    public fb: FormBuilder
  ) {
   }

  ngOnInit() {
    
  }


  onNoClick() {
    this.dialogRef.close(false);
  }

  againClick(){
    this.dialogRef.close(true);
  }
}
