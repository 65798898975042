<div class="form-container">
  <span></span> 
  <form #f="ngForm" [formGroup]="onboardingForm" class="form" fxLayout="column">
    <div class="speech">
    <h1 class="title">¡Completemos tus datos!</h1>
    <h3 class="subtitle">HolaDOC consultas médicas online 24/7.</h3>
    <h4 class="description">Completa los datos del titular para la historia médica</h4>
  </div>
       <div class="input-container"> 
    <label for="Genero" class="title-label">Género</label>
    <mat-form-field appearance="outline" fxFlex="100" class="shd-gender">
      <mat-select name="gender" disableOptionCentering panelClass="defaultPanelClass" formControlName="gender" class="altitude">
        <mat-option [value]="1">Masculino</mat-option>
        <mat-option [value]="2">Femenino</mat-option>
        <mat-option [value]="3">Otro</mat-option>
      </mat-select>
    </mat-form-field>
   </div>
  <div class="input-container">
    <label for="FechaNacimiento" class="title-label">Fecha de nacimiento</label>
    <mat-form-field appearance="outline">
      <input formControlName="dateOfBirth" matInput [matDatepicker]="picker" placeholder="DD/MM/YYYY"
        (click)="picker.open()"  class="altitude" readonly/>
      <!--   <input formControlName="dateOfBirth" matInput [matDatepicker]="picker" placeholder="DD/MM/YYYY"
        (click)="picker.open()" [max]="today" class="altitude"/>
         -->
      <ng-container matPrefix>
        <mat-datepicker-toggle [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </ng-container>
    </mat-form-field>
  </div>
  <div *ngIf="(onboardingForm.get('dateOfBirth')?.touched || onboardingForm.get('dateOfBirth')?.dirty || f.submitted)">
    <div *ngIf="onboardingForm.get('dateOfBirth')?.hasError('max')" class="error-message-text">Fecha de nacimiento inválida.</div>
   
  </div>

    <div style="display: flex;align-items: center;" class="margen-tope">
      <p class="base-input">¿Tienes hijos menores de edad?</p><mat-slide-toggle  [checked]="children"   (click)="children=!children" class="botonslider">         
      </mat-slide-toggle>
    
    </div>

    <div>
      <button class="submit-button" (click)="singIn()" >Actualizar datos</button>
    </div>
  </form>


</div>