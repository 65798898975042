import { AbstractControl, ValidationErrors } from '@angular/forms';


// custom validator to check that two fields match
export function dateValidator(
  control: AbstractControl
): ValidationErrors | null {

  const dateOfBirthControl = control.get('dateOfBirth');
  const dateOfBirth = dateOfBirthControl?.value;
  const today = new Date();
  



  
  if (dateOfBirth) {
    if (dateOfBirth > today) {
    dateOfBirthControl.setErrors({ max: true });
      return { max: true };

    }else {
      return null;
    }      
   
  }else{
    dateOfBirthControl?.setErrors({ max: true });
      return { max: true };

  }
 
}


