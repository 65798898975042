import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShdOnboardingHttpService } from './http/http.service';
import { OnboardingService } from './services/onboarding.service';


@NgModule({
  declarations: [
],
  imports: [
    CommonModule,
    
  ],
  providers:[
    ShdOnboardingHttpService,
    OnboardingService
  ],
  exports:[]
})
export class CoreModule {}
