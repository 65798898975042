import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const defaultHeaders = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'Accept-Language': 'es',
};

/**
 * Provides a base implementation for http methods.
 */


@Injectable({
  providedIn: 'root',
})
export class ShdOnboardingHttpService {
  constructor(private http: HttpClient) {}

  get$<T>(url: string, options?: any): Observable<HttpEvent<T>> {
    return this.http.get<T>(environment.baseUrl + url, {
      ...options,
      headers: {
        ...options?.headers,
        ...defaultHeaders,
      },
    });
  }

  getBlob$<T>(url: string, options?: any): Observable<HttpEvent<T>> {
    return this.http.post<T>( environment.baseUrl +url,'', {
      ...options,
      headers: {
        ...options?.headers,
        ...defaultHeaders,
      },
      responseType: 'blob',
    });
  }

  post$<T>(url: string, body: any, options?: any): Observable<HttpEvent<T>> {
    return this.http.post<T>( environment.baseUrl +url, body, {
      ...options,
      headers: {
        ...options?.headers,
        ...defaultHeaders,
      },
    });
  }

  put$<T>(url: string, body: any, options?: any): Observable<HttpEvent<T>> {
    return this.http.put<T>( environment.baseUrl + url, body, {
      ...options,
      headers: {
        ...options?.headers,
        ...defaultHeaders,
      },
    });
  }

  delete$<T>(url: string, options?: any): Observable<HttpEvent<T>> {
    return this.http.delete<T>( environment.baseUrl+ url, {
      ...options,
      headers: {
        ...options?.headers,
        ...defaultHeaders,
      },
    });
  }

  patch$<T>(url: string, body: any, options?: any): Observable<HttpEvent<T>> {
    return this.http.patch<T>( environment.baseUrl+ url, body, {
      ...options,
      headers: {
        ...options?.headers,
        ...defaultHeaders,
      },
    });
  }
}
