import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { SuccessModalComponent } from './success-dialog/success-modal.component';

@Component({
  selector: 'shd-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent {

 
  
 

  constructor( ) { 
  }

 
}
